<template>
  <Breadcrumbs main="rydpay APIs" title="Order State Management"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <div class="ribbon-wrapper card">
          <div class="card-body">
            <div class="ribbon ribbon-clip ribbon-secondary">Index of Content</div>

            <p>
              On this page, you will find detailed information about the Ryd order state machine, its transitions, and the specifics of each state.
              The topics covered include:
            </p>

            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('order-state-machine-overview')"
                   class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Order State Machine Overview</a>
                An introduction to the order state machine and its purpose.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('state-transitions')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> State Transitions</a>
                Detailed information on how orders move from one state to another.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('state-transitions')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> State Machine Types by Product/Service</a>
                Explanation of which state machine types are used by different products/services.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('detailed-state-descriptions')"
                   class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Detailed State Description
                </a>
                Comprehensive descriptions of each state within the order state machine.
              </li>


            </ul>

          </div>
        </div>


        <div class="card" id="order-state-machine-overview">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>Order State Machine Overview</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">

            The Ryd API utilizes a comprehensive order state machine to manage transactions efficiently and accurately. This state machine ensures that every transaction progresses through a well-defined series of states, from creation to completion.

            By adhering to this structured approach, Ryd guarantees the integrity and consistency of transaction processing across different services, including fuelling, car wash, and EV charging.

            <br/> <br/>
            The state machine is designed to handle various transaction scenarios, including successful completions, errors, and retries. Each state within the machine represents a specific phase in the transaction lifecycle, and the transitions between these states are governed by predefined rules and conditions.
            <br/> <br/>
            There are two primary types of transactions managed by the Ryd order state machine: Prepay and Postpay. Each type has its own set of states and transitions to accommodate different payment workflows. The type used for each product/service (fuelling, car wash, and EV charging) is determined by the petrol stations or specific circumstances of the product/service.
            For more details, see <a href="/integration-guide/rydpay-apis/order-state-management#state-machine-types-by-product">State Machine Types By Product/Service</a>.
          </div>
        </div>


        <div class="card" id="state-transitions">
          <div class="card-header bg-success d-flex justify-content-between align-items-center">
            <h5>State Transitions</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Description:</h5>
            <ul class="list-group">
              <li class="list-group-item">
                The following diagrams illustrate the state transitions for Prepay and Postpay transactions in the Ryd
                order state machine.
              </li>
              <li class="list-group-item">
                Each transition represents a specific action or event that causes the order to move from one state to
                another. The transitions are categorized as either synchronous (direct actions) or asynchronous (polling
                or waiting for external events).
              </li>
              <li class="list-group-item">
                Click on the image below to view a larger version:
              </li>
            </ul>
            <br/>
          </div>

          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-6">
                <div class="row my-gallery gallery" id="aniimated-thumbnials-2" itemscope="" data-pswp-uid="1">
                  <figure class="col-md-4 col-6 img-hover hover-15" @click="showImg(2)" itemprop="associatedMedia" itemscope="">
                    <div class="thumbnail-container">
                      <img :src="require('../../../../assets/images/ryd/ryd-order-state-simple-0415.png')" itemprop="thumbnail2" alt="Image description" class="img-fluid thumbnail">
                    </div>
                  </figure>
                </div>
              </div>
            </div>
          </div>


          <br/>
        </div>

        <div class="card" id="state-machine-types-by-product">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>State Machine Types by Product/Service</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Fuelling</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Fuelling transactions can utilize either the prepay or postpay state machines, as determined by the petrol stations. This configuration is defined by the petrol stations themselves, not by Ryd.
              </li>
              <li class="list-group-item">
                <b>Prepay State Machine</b>: Used when customers authorize payment before fuelling. This state machine ensures
                the transaction amount is pre-authorized before the service is provided.
              </li>
              <li class="list-group-item">
                <b>Postpay State Machine</b>: Used when customers fuel first and pay later. This setup allows for the
                transaction amount to be finalized after the fuelling is completed.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Car Wash</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Car wash transactions exclusively use the prepay state machine:
              </li>
              <li class="list-group-item">
                <b>Prepay State Machine</b>: Customers must authorize payment before the car wash service is provided.
                This ensures that the transaction is secured prior to service delivery.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>EV Charging</h5>
            <ul class="list-group">
              <li class="list-group-item">
                EV charging transactions also utilize the prepay state machine:
              </li>
              <li class="list-group-item">
                <b>Prepay State Machine</b>:Customers are required to authorize payment before starting the charging session.
                This ensures that the transaction amount is pre-authorized before the service is provided, securing the transaction from the outset.
              </li>
              <li class="list-group-item">
                Most of the charging sessions take a long time, and the final price will be shared by <a href="/integration-guide/rydpay-apis/terms-and-definitions#cpo">Charging Point Operators (CPOs)</a> anywhere from a few seconds to a few days after the completion of an EV charging session.
                This approach ensures that customers are not delayed at the charging station and can leave as soon as their vehicle is charged, with billing being handled asynchronously.
              </li>
            </ul>
            <br/>

          </div>


          <div class="card" id="detailed-state-descriptions">
            <div class="card-header bg-success d-flex justify-content-between align-items-center">
              <h5>Detailed State Descriptions</h5>
              <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
                <i class="icofont icofont-arrow-up"></i> Back to Top
              </button>
            </div>
            <div class="card-body">
              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>IMPORTANT: Final and not final states
              </h5>
              <ul class="list-group">
                <li class="list-group-item"><b>Final states:</b> Please are aware that while an order progresses through
                  the state engine it can be INTERMEDIATE states or can reach one of x FINAL states.<br/>
                  Final states are exclusively <b>COMPLETED, COMPLETED_MANUALLY, FAILED, CANCELLED</b>
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>CREATED</h5>
              <ul class="list-group">
                <li class="list-group-item"><b>Description:</b> This is the very first state of the order, upon order
                  creation with the <code>POST /v4/orders</code> endpoint. When an order is in the <code>CREATED</code>
                  state, it
                  signifies that some initial product details have been established for that order. For instance, if a
                  customer is purchasing fuel, they have selected the pump number.
                </li>
                <ul class="list-group">
                  <li class="list-group-item"><b>[MANUAL FLOW]</b> The order won't remain in this state for an extended
                    period. As soon as the customer completes their interaction with the product (e.g., finishes filling
                    their vehicle's gas tank), the order will transition to the <code>PAYMENT_PENDING</code> state. At
                    this point, it is required for the API client to refrain from making an <code>/authorize</code>
                    request immediately; instead, they should wait until the order advances to the
                    <code>PAYMENT_PENDING</code> state.
                  </li>
                  <li class="list-group-item"><b>[AUTOMATIC FLOW]</b> If the API client does not initiate a request to
                    the
                    <code>/authorize</code> endpoint at this stage, the order will remain in the <code>CREATED</code>
                    state. This is why it is imperative for the API client to promptly make an <code>/authorize</code>
                    request as soon as it detects the order in the <code>CREATED</code> state within the AUTOMATIC flow.
                  </li>
                </ul>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>PAYMENT_PENDING</h5>
              <ul class="list-group">
                <li class="list-group-item"><b>[MANUAL FLOW]</b> In the MANUAL flow, the <code>PAYMENT_PENDING</code>
                  state is very
                  important. It signifies that the customer currently possesses the product. This implies that at this
                  specific moment, we possess information regarding both the price of the product and the quantity
                  obtained by the customer. This characteristic is unique to the MANUAL flow, as it enables us to
                  precisely determine the precise amount to charge the customer. You can employ the charge amount
                  obtained during this step as the authorization amount in your request to the <code>/authorize</code>
                  endpoint.
                </li>
                <li class="list-group-item"><b>[AUTOMATIC FLOW]</b> Unlike the MANUAL flow, if the order is in the
                  <code>PAYMENT_PENDING</code> state, the customer is not in possession of the product. In this flow,
                  it's
                  important
                  to note that the <code>PAYMENT_PENDING</code> state does not directly concern the API clients. While
                  you
                  might see
                  this state in the state history, its significance primarily lies within our internal services.
                  Specifically, it serves as a trigger for our internal systems to decide when to initiate the
                  authorization for charging with a specific Payment Service Provider. API clients need not be directly
                  concerned with this state, as its role is primarily internal for payment processing purposes.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>PAYMENT_AUTHORIZATION_STARTED</h5>
              <ul class="list-group">
                <li class="list-group-item">When the order reaches this state, it means that the payment authorization
                  flow was triggered and initiated.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>PAYMENT_AUTHORIZED</h5>
              <ul class="list-group">
                <li class="list-group-item"><b>Description:</b> TWhen the order is in this state, it signifies that the
                  user has given us permission to charge them a specific amount of money. At this point, the API client
                  should send a request to the <code>POST /orders/{orderId}/acquire</code> endpoint. This request
                  instructs our system to proceed with the charging process.
                </li>
                <ul class="list-group">
                  <li class="list-group-item"><b>[MANUAL FLOW]</b> In the MANUAL flow, we have already obtained
                    authorization for the precise amount to be charged through the <code>POST
                      /order/{orderId}/authorize</code>
                    endpoint. We have the exact value because, as previously explained, in the MANUAL flow, the customer
                    already possesses the product when the order reaches the <code>PAYMENT_PENDING</code> state.
                  </li>
                  <li class="list-group-item"><b>[AUTOMATIC FLOW]</b> In the AUTOMATIC flow, the authorized amount may
                    not
                    necessarily match the final charge to the customer, primarily because the customer has not yet
                    received the product. In this scenario, the authorized amount serves as an upper limit, indicating
                    the
                    maximum amount the customer has granted us permission to charge. However, our system ensures that
                    the
                    total charge will not surpass this maximum value. This way, the customer cannot acquire a product
                    exceeding the authorized limit.
                  </li>
                </ul>
              </ul>
              <br/>


              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>PRODUCT_PENDING</h5>
              <ul class="list-group">
                <li class="list-group-item"><b>[MANUAL FLOW]</b> In the MANUAL flow, this state holds no relevance for
                  the
                  API client. While you might see this state in the order's state history, its significance primarily
                  lies
                  within our internal services.
                </li>
                <li class="list-group-item"><b>[AUTOMATIC FLOW]</b> In the AUTOMATIC flow, the
                  <code>PRODUCT_PENDING</code> state is
                  very important. It signifies that the user must physically proceed with obtaining the product. For
                  instance, this might involve taking out a nozzle and filling the vehicle's gas tank. During this step,
                  the customer doesn't need to engage with any graphical user interfaces (GUI) or digital apps. The
                  interaction occurs solely in the physical world.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>PRODUCT_ACQUISION_STARTED</h5>
              <ul class="list-group">
                <li class="list-group-item">When the order reaches this state, it means that the product acquistion flow
                  was triggered and initiated.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>PRODUCT_ACQUIRED</h5>
              <ul class="list-group">
                <li class="list-group-item">When the order reaches this state, it means the customer has completed all
                  necessary interactions with the app or physical product and is allowed to leave the location. In case
                  we
                  encounter any issues preventing us from charging the customer later, the customer is still permitted
                  to
                  leave, because our system has all the necessary information to address the situation later.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>READY_FOR_INVOICE</h5>
              <ul class="list-group">
                <li class="list-group-item">This state holds no relevance for the API client. It's important not to
                  build
                  your application's logic around it. Please keep in mind that its meaning and how it works may change
                  in
                  the future, or it might be removed altogether. When the order reaches this state, we start the payment
                  charge with third-party payment providers, a step often referred to as "transaction capturing." The
                  charge amount is determined by subtracting any applicable discounts from the products' value. If the
                  customer had previously authorized a higher amount than the charge, we release the difference between
                  these two values.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>COMPLETED - [FINAL STATE]</h5>
              <ul class="list-group">
                <li class="list-group-item">A stable state that marks the successful finalization of the order. The
                  money
                  has been charged and there is nothing to do anymore.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>COMPLETED_MANUALLY - [FINAL STATE]</h5>
              <ul class="list-group">
                <li class="list-group-item">This special state is specifically utilized to indicate an order that has
                  undergone manual adjustments by an administrator. Such adjustments are infrequent and are only
                  employed
                  in situations where manual intervention is necessary to rectify an order that is in a problematic
                  state.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>REJECTED</h5>
              <ul class="list-group">
                <li class="list-group-item">internal and transient state towards <code>CANCELLED</code>, money will be
                  released when
                  transitioning to <code>CANCELLED</code>.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>PROBLEM</h5>
              <ul class="list-group">
                <li class="list-group-item">semi-stable state which requires manual intervention as the system does not
                  know if the product has been handed over to a customer or not (money stays reserved).
                </li>
                <li class="list-group-item"><b>[MANUAL FLOW]</b>: client needs to send the user into the shop to clarify
                  if
                  he paid or not!
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>CANCELLED - [FINAL STATE]</h5>
              <ul class="list-group">
                <li class="list-group-item">user or client cancelled the order.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>FAILED [FINAL STATE]</h5>
              <ul class="list-group">
                <li class="list-group-item">for some reason (no funds, pump already reserved, ...) the purchase failed.
                  No
                  further action required. User(client) might retry by creating a new order.
                </li>
              </ul>
              <br/>

              <br/>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {scrollToCard, scrollToRootHash, scrollToTop} from "@/utils/anchorUtils";

export default {
  name: "RydpayApisOrderState",
  keywords: ['Order State', 'State Transitions', 'State Machine', 'Order State Machine', 'State Descriptions'],
  data() {
    return {
      loggedInUserGroup: '',
      lightBoxImages: [],
      visible: false,
      gallery1: [
        {
          image: 'prepay-sequence-diagram-full.png'
        },
        {
          image: 'postpay-sequence-diagram-full.png'
        },
        {
          image: 'ryd-order-state-simple-0415.png'
        }
      ],
    }
  },
  mounted() {
    const {userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserGroup = userGroup;
    this.scrollToRootHash();
    this.gallery1.forEach(item => {
      this.lightBoxImages.push(require('@/assets/images/ryd/' + item.image))
    });
  },
  methods: {
    scrollToRootHash,
    scrollToCard,
    scrollToTop,
    showImg(index) {
      this.index = index;
      this.visible = true;
      const imageUrl = this.lightBoxImages[this.index];

      this.$swal({
        imageUrl,
        imageAlt: 'Image',
        showCloseButton: true,
        showConfirmButton: false,
        customClass: 'swal-wide',
        width: '80%'
      });
    },
  },
  watch: {
    '$route.hash': function () {
      this.scrollToRootHash();
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/swal_custom.scss";
</style>

